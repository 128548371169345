/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-return-assign */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
import {
  Add, DeleteOutline, DeleteOutlineOutlined, Remove,
} from '@material-ui/icons';
import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import EmptyCartIcon from '../../assets/images/empty-cart.svg';
import * as appActions from '../../store/app/actions';
import * as cartActions from '../../store/cart/actions';
import currencyFormat from '../../helpers/currencyFormat';
import getLotteryColor from '../../helpers/getLotteryColor';
import { ReactComponent as IconLoteria } from '../../assets/images/loteria.svg';
import ShortTime from '../../assets/images/short-time.svg';
import Button from '../Button';
import cartService from '../../services/cart';
import { history } from '../../helpers/history';

export function CartContent({
  cartProps: props, setLoadingCart = null, isMobile, loadingCart,
}) {
  const { cartData } = props;
  const reduxSelector = useSelector((state) => state);
  const [loading, setLoading] = useState(['', false]);
  const [errorOnAdd, setErrorOnAdd] = useState(['']);
  const cartDataGroupedBySKU = cartData?.reduce((cartItem, item) => ({
    ...cartItem,
    [`${item?.options?.concourse}|${item?.options?.type_game}`]: [...(cartItem[`${item?.options?.concourse}|${item?.options?.type_game}`] || []), item],
  }), {});

  useEffect(() => {
    const imagesPreload = [IconLoteria];
    imagesPreload.forEach((image) => {
      const newImage = new Image();
      newImage.src = image;
      window[image] = newImage;
    });
  }, []);

  function itemLoading(id, status) {
    setLoading([id, status]);
  }

  function removeItem(item) {
    itemLoading([item.id], true);
    cartService.removeItems(item.rowId, localStorage.getItem('cartKey')).then((response) => {
      props.cartActions.removeToCart(item);
      props.cartActions.updateCart(response.data?.content);
      if (response.data?.content?.length === 0) {
        itemLoading('', false);
        props.cartActions.destroyCart();
        return;
      }

      itemLoading([item.id], false);
    });
  }

  function changeQty(item, type) {
    const cartItems = props.cartData;
    const index = cartItems?.findIndex((_item) => _item.id === item.id);
    const itemNewQty = type === 'sum' ? item.qty + 1 : item.qty - 1;
    itemLoading([item.id], true);

    if (cartItems[index].qty === 1 && type === 'sub') {
      return removeItem(cartItems[index]);
    }

    if (index > -1) {
      return cartService.updateItem(cartItems[index].rowId, localStorage.getItem('cartKey'), itemNewQty).then((response) => {
        if (response.code === 0) {
          props.cartActions.destroyCart();
	        props.appActions.handleCart();
          return;
        }

        if (response.erros) {
          setErrorOnAdd((state) => [...state, item.id]);
          setTimeout(() => {
            setErrorOnAdd((state) => state.filter((id) => id !== item.id));
          }, 2000);
          itemLoading([item.id], false);
          return;
        }

        props.cartActions.updateCart(response.data?.content);
        itemLoading([item.id], false);
      });
    }
    return 1;
  }

  function handleCartButton() {
    if (!isMobile) {
      props.appActions.handleCart();
    }
    if (Object.entries(props.userData).length > 0) {
      history.push('/checkout');
    } else {
      window.location.href = `${process.env.REACT_APP_ON_BOARDING_URL}/login?redirect_to=${window.location.origin}/checkout&action=auth`;
    }
  }

  function cartTotals() {
    let total = 0;
    props.cartData?.map((item) => (total = parseFloat(total) + (parseFloat(item.price) * parseInt(item.qty, 10))));
    return currencyFormat(total);
  }

  function removeConcourseItems(key) {
    const selectedGroup = props.cartData?.reduce((cartItem, item) => ({
      ...cartItem,
      [`${item?.options?.concourse}|${item?.options?.type_game}`]: [...(cartItem[`${item?.options?.concourse}|${item?.options?.type_game}`] || []), item],
    }), {});
    const loadingIds = selectedGroup[key]?.map((item) => item.id);
    const removedRowIds = selectedGroup[key]?.map((item) => item.rowId);
    itemLoading(loadingIds, true);
    cartService.removeItems(removedRowIds, localStorage.getItem('cartKey')).then((response) => {
      itemLoading(loadingIds, false);
      if (removedRowIds.length === props.cartData.length) {
        itemLoading('', false);
        props.cartActions.destroyCart();
        return;
      }
      props.cartActions.updateCart(response.data?.content);
    });
  }

  function cleanCart() {
    setLoadingCart(true);
    itemLoading('', false);
    cartService.destroyCart(localStorage.getItem('cartKey')).then(() => {
      props.cartActions.destroyCart();
      setLoadingCart(false);
    });
  }

  const isLoadingRow = (item = null) => (item ? (loading[0].includes(item.id) && loading[1]) : loading[1]);
  return (
    <>
      <main className="cart-div">
        <div id="cart-content-items" className="cart-box">
          {isMobile && <button type="button" disabled={props.cartData <= 0} onClick={cleanCart} className="clean-cart-button">Limpar</button>}
          {
            cartDataGroupedBySKU && Object.entries(cartDataGroupedBySKU)?.length > 0 ? Object.entries(cartDataGroupedBySKU).map(([key, items]) => (
              <section className="group" id={key} key={(key + items[0]).toString()}>
                <div className="product-group">
                  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_2_744)">
                      <path
                        d="M21.5414 10.992C21.5413 10.9921 21.5413 10.9921 21.5412 10.9922C20.8861 11.6472 20.0157 12.0079 19.0905 12.0079L13.018 12.0079L12.5879 12.0079L12.5879 11.5779L12.5876 5.50483C12.5935 4.57883 12.9577 3.70944 13.6131 3.05404C14.2682 2.39891 15.1351 2.03814 16.0541 2.03817C16.9796 2.03814 17.85 2.39889 18.505 3.05393C19.0631 3.61206 19.4076 4.32659 19.4973 5.09829C20.2689 5.18797 20.9834 5.53238 21.5414 6.09044C22.1963 6.7454 22.557 7.61579 22.557 8.54127C22.557 9.46668 22.1963 10.3371 21.5414 10.992Z"
                        fill={getLotteryColor(cartDataGroupedBySKU[key][0].options.type_game)}
                      />
                      <path
                        d="M10.9925 21.5419C10.9924 21.542 10.9924 21.542 10.9923 21.5421C10.3372 22.1971 9.46684 22.5578 8.54154 22.5578C7.61614 22.5578 6.7458 22.1971 6.09087 21.5422C5.53275 20.984 5.18833 20.2695 5.09868 19.4978C3.37811 19.2958 2.03869 17.8287 2.03867 16.0547C2.03865 15.1356 2.39946 14.2686 3.05462 13.6135C3.70999 12.9581 4.57934 12.5939 5.5025 12.588L11.5781 12.5884L12.0081 12.5884L12.0081 13.0184L12.0081 19.0911C12.0081 20.0166 11.6474 20.887 10.9925 21.5419Z"
                        fill={getLotteryColor(cartDataGroupedBySKU[key][0].options.type_game)}
                      />
                      <path
                        d="M18.5097 21.5414C17.8548 22.1963 16.9845 22.557 16.0591 22.557C15.1337 22.557 14.2633 22.1963 13.6083 21.5412C12.9532 20.8861 12.5925 20.0157 12.5925 19.0903L12.5925 13.0176L12.5925 12.5875L13.0225 12.5875L19.0953 12.5872C21.008 12.5994 22.5619 14.1546 22.5619 16.054C22.5619 16.9794 22.2012 17.8498 21.5463 18.5048C21.5462 18.5048 21.5462 18.5049 21.5461 18.5049C20.9879 19.063 20.2735 19.4075 19.5019 19.4972C19.4122 20.2689 19.0678 20.9833 18.5097 21.5414Z"
                        fill={getLotteryColor(cartDataGroupedBySKU[key][0].options.type_game)}
                        fillOpacity="0.4"
                      />
                      <path
                        d="M12.0128 12.008L11.5828 12.008L5.51025 12.008C4.58482 12.008 3.71442 11.6472 3.05938 10.9922C2.40435 10.3371 2.04361 9.46669 2.04362 8.54124C2.04361 7.61578 2.4043 6.7454 3.05926 6.09044C3.61729 5.53241 4.33171 5.188 5.10331 5.09831C5.19304 4.32662 5.53753 3.61207 6.09566 3.05394C6.75069 2.39891 7.62106 2.03817 8.54645 2.03818C10.4458 2.03821 12.0009 3.59215 12.0131 5.50218L12.0128 11.578L12.0128 12.008Z"
                        fill={getLotteryColor(cartDataGroupedBySKU[key][0].options.type_game)}
                        fillOpacity="0.4"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2_744">
                        <rect width="24.6" height="24.6009" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  <h2 className="product-group-name">
                    {`${cartDataGroupedBySKU[key][0].name} - Concurso ${cartDataGroupedBySKU[key][0].options.concourse?.number}`}
                  </h2>
                  <DeleteOutlineOutlined onClick={() => removeConcourseItems(key)} style={{ cursor: 'pointer', fontSize: 20, fill: '#23262F' }} />
                </div>
                <div className="item-group" style={{ borderTop: `8px solid ${getLotteryColor(cartDataGroupedBySKU[key][0].options.type_game)}` }}>
                  {items.map((item) => (
                    <div id={item.id} className={`line ${isLoadingRow(item) && 'disabled'}`}>
                      <h1 className="name">{item.options.ref}</h1>
                      <div className="quantity">
                        <button type="button" disabled={isLoadingRow(item)} className="sub" onClick={() => changeQty(item, 'sub')}>
                          {
                            item.qty > 1
                              ? <Remove style={{ fill: '#209869' }} />
                              : <DeleteOutline style={{ fill: '#209869', fontSize: 20 }} />
                          }
                        </button>
                        <span className="qty">{item.qty}</span>
                        <button type="button" disabled={isLoadingRow(item) || item?.qty === 3} className="sum" onClick={() => changeQty(item, 'sum')}>
                          <Add style={{ fill: '#209869' }} />
                        </button>
                      </div>
                      <div className={`subtotal ${errorOnAdd.indexOf(item.id) >= 0 ? 'with-error' : ''}`}>{isLoadingRow(item) ? <CircularProgress size={14} /> : errorOnAdd.indexOf(item.id) >= 0 ? 'Indisponível' : currencyFormat(item.price * item.qty)}</div>
                    </div>
                  ))}
                </div>
              </section>
            )) : (
              <>
                {!reduxSelector.cartReducer.expireCartFeedback ? (
                  <div className="empty">
                    <img src={EmptyCartIcon} alt="Carrinho vazio" />
                    <span className="empty-text">Seu carrinho está vazio.</span>
                  </div>
                ) : (
                  <div className="empty">
                    <img src={ShortTime} alt="Carrinho vazio" width={300} height={300} />
                    <span className="empty-text">Seu carrinho expirou, refaça seu carrinho.</span>
                  </div>
                )}
              </>
            )
          }
          {loadingCart && <span className="loading-cart"><CircularProgress size={24} /></span>}
        </div>
      </main>
      {!isMobile && (
        <div className="actions">
          <span className="cart-total">
            <small>Total:</small>
            <span className="total">{isLoadingRow(null) ? <CircularProgress size={14} /> : cartTotals()}</span>
          </span>
          <Button type="button" disabled={(cartDataGroupedBySKU && Object.entries(cartDataGroupedBySKU).length === 0) || loadingCart || loading[1]} width="100%" color="green" label="Fechar compra" action={handleCartButton} />
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  userData: state.accountReducer.userData,
  appData: state.appReducer,
  cartData: state.cartReducer.items,
  cartCredits: state.cartReducer.credits,
});

const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(appActions, dispatch),
  cartActions: bindActionCreators(cartActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartContent);
