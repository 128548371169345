export function UseTermsHTML() {
  return `
    <div class="WordSection1">
    <p class="MsoNormal" align="center" style="font-size:1.5rem;margin-top:0in;margin-right:.3pt;
margin-bottom:1.1pt;margin-left:.5pt;line-height:107%"><b><span lang="PT-BR" style="color:windowtext">TERMOS E CONDIÇÕES DE USO</span></b><span lang="PT-BR" style="color:windowtext"> </span></p>

    <p class="MsoNormal" align="left" style="margin-top:0in;margin-right:0in;
margin-bottom:1.05pt;margin-left:0in;text-align:left;text-indent:0in;
line-height:107%"><span lang="PT-BR" style="color:windowtext"> </span></p>

    <p class="MsoNormal" align="left" style="margin-top:0in;margin-right:0in;
margin-bottom:2.05pt;margin-left:0in;text-align:left;text-indent:0in;
line-height:107%"><span lang="PT-BR" style="color:windowtext"> </span></p>

    <h1 style="margin-top:0in;margin-right:0in;margin-bottom:5.15pt;text-indent:-.25in"><span lang="PT-BR" style="line-height:108%">1.<span style="font:7.0pt &quot;Times New Roman&quot;"> </span></span><span lang="PT-BR" style="color:windowtext">DO OBJETO E DA ACEITAÇÃO </span></h1>

    <p class="MsoNormal"><span lang="PT-BR">1.1. O presente documento tem por objetivo
reger os serviços de intermediação prestados pela Unindo Sonhos
(@Unindosonhoss) – PC de S LEITE – LTDA, inscrição no CNPJ sob o nº
16.742.811/0001-06, com sede na Av. Nossa Senhora da Consolata, 645 c – Centro
– Boa Vista/Roraima – CEP: 69301 - 011, com Contrato Social arquivado na Junta
Comercial do Estado de Roraima sob o NIRE 14800055898, denominada
INTERMEDIADORA, àqueles que acessarem o Portal <a href="http://www.unindosonhos.com.br/"><span style="color:black">http://www.unindosonhos.com.b</span></a><a href="http://www.unindosonhos.com.br/"><span style="color:black">r</span></a><a href="http://www.unindosonhos.com.br/"><span style="color:black;text-decoration:
none"> </span></a><a href="http://www.unindosonhos.com.br/"><span style="color:black;text-decoration:none">p</span></a>ara os fins aqui
descritos, os quais são denominados simplesmente USUÁRIOS, cujo acesso se dará
por meio da rede mundial de computadores/internet/aplicativo.<b> </b></span></p>

    <p class="MsoNormal"><span lang="PT-BR">1.2. O USUÁRIO somente poderá se utilizar
dos serviços a ele disponibilizados após ciência integral destes termos, bem
como depois de expressamente com eles concordar, caso contrário não deve
utilizá-los.<b> </b></span></p>

    <p class="MsoNormal"><span lang="PT-BR">1.3. O objeto do presente “Termos e
Condições de Uso” é a intermediação realizada pela INTERMEDIADORA entre o
USUÁRIO e Casas Lotéricas credenciadas pela CAIXA ECONÔMICA FEDERAL, para a
realização, em nome do </span></p>

    <p class="MsoNormal"><span lang="PT-BR">USUÁRIO, de apostas em jogos lotéricos
oficiais, por meio de bolões, ou jogos individuais, nos termos do art. 653 do
Código Civil, servindo este instrumento como formalização da manifestação da
vontade de contratar realizada por meio da ciência dos termos constantes do
Instagram @unindosonhoss e do respectivo pagamento realizado.<b> </b></span></p>

    <p class="MsoNormal" align="left" style="margin-top:0in;margin-right:0in;
margin-bottom:2.0pt;margin-left:0in;text-align:left;text-indent:0in;line-height:
107%"><span lang="PT-BR" style="color:windowtext"> </span></p>

    <h1 style="margin-top:0in;margin-right:0in;margin-bottom:5.15pt;text-indent:-.25in"><span lang="PT-BR" style="line-height:108%">2.<span style="font:7.0pt &quot;Times New Roman&quot;"> </span></span><span lang="PT-BR" style="color:windowtext">DA INTERMEDIAÇÃO </span></h1>

    <p class="MsoNormal"><span lang="PT-BR" style="color:windowtext">2.1. A atuação
disponibilizada pelo Portal </span><span lang="PT-BR"><a href="http://www.unindosonhos.com.br/"><span style="color:windowtext">http://www.unindosonhos.com.b</span></a><a href="http://www.unindosonhos.com.br/"><span style="color:windowtext">r</span></a><a href="http://www.unindosonhos.com.br/"><span style="color:windowtext;
text-decoration:none"> </span></a><a href="http://www.unindosonhos.com.br/"><span style="color:windowtext;text-decoration:none">c</span></a></span><span lang="PT-BR" style="color:windowtext">onsiste na intermediação entre o USUÁRIO e
Casas Lotéricas credenciadas pela CAIXA ECONÔMICA FEDERAL, para a realização,
em nome do USUÁRIO, de apostas em jogos lotéricos oficiais, por meio de bolões,
nos termos do art. 653 do Código Civil, servindo o aceite dado pelo USUÁRIO no
Portal ou Aplicativo como formalização da manifestação da vontade de
contratar. </span></p>

    <p class="MsoNormal"><span lang="PT-BR" style="color:windowtext">2.2. Pela
intermediação prevista na Cláusula 2.1 dos presentes “Termos e Condições de
Uso”, o USUÁRIO pagará à CONTRATADA, valor determinado, constante do Portal</span><span lang="PT-BR"><a href="http://www.unindosonhos.com.br/"><span style="color:windowtext;
text-decoration:none"> </span></a><a href="http://www.unindosonhos.com.br/"><span style="color:windowtext">http://www.unindosonhos.com.b</span></a><a href="http://www.unindosonhos.com.br/"><span style="color:windowtext">r</span></a><a href="http://www.unindosonhos.com.br/"><span style="color:windowtext;
text-decoration:none"> </span></a><a href="http://www.unindosonhos.com.br/"><span style="color:windowtext;text-decoration:none">o</span></a></span><span lang="PT-BR" style="color:windowtext">u respectivo aplicativo, já incluída a
respectiva retribuição nos termos do art. 676 do Código Civil, por se tratar de
mandato oneroso. </span></p>

    <p class="MsoNormal"><span lang="PT-BR" style="color:windowtext">2.3. O pagamento
deverá ser realizado por meio de PIX ou depósito bancário (exclusivo para
compra de crédito), cujo comprovante deverá ser guardado pelo USUÁRIO e
apresentado quando solicitado para fim de conferência e, bem assim, como
condição ao exercício de eventuais benefícios e direitos. </span></p>

    <p class="MsoNormal"><span lang="PT-BR" style="color:windowtext">Eventuais dúvidas
sobre os presentes termos deverão ser sanadas por meio do Portal </span><span lang="PT-BR"><a href="http://www.unindosonhos.com.br/"><span style="color:windowtext">http://www.unindosonhos.com.b</span></a><a href="http://www.unindosonhos.com.br/"><span style="color:windowtext">r</span></a><a href="http://www.unindosonhos.com.br/"><span style="color:windowtext;
text-decoration:none">,</span></a><a href="http://www.unindosonhos.com.br/"><span style="color:windowtext;text-decoration:none"> </span></a></span><span lang="PT-BR" style="color:windowtext">no canal de atendimento ao cliente. </span></p>

    <p class="MsoNormal"><span lang="PT-BR" style="color:windowtext">2.4. A
INTERMEDIADORA receberá, em nome do USUÁRIO, eventuais valores oriundos de
prêmios junto à CAIXA ECONÔMICA FEDERAL, com consequente crédito em conta
corrente de titularidade do USUÁRIO, conforme disposto no art. 653 e seguintes
do Código Civil. </span></p>

    <p class="MsoNormal" align="left" style="margin-top:0in;margin-right:0in;
margin-bottom:7.05pt;margin-left:0in;text-align:left;text-indent:0in;
line-height:107%"><span lang="PT-BR" style="color:windowtext"> </span></p>

    <h1 style="margin-top:0in;margin-right:0in;margin-bottom:6.85pt;text-indent:-.25in"><span lang="PT-BR" style="line-height:108%">3.<span style="font:7.0pt &quot;Times New Roman&quot;"> </span></span><span lang="PT-BR" style="color:windowtext">CONDIÇÕES NECESSÁRIAS PARA A UTILIZAÇÃO DA
INTERMEDIAÇÃO PELO USUÁRIO</span></h1>

    <p class="MsoNormal"><span lang="PT-BR">3.1. Efetuar cadastro prévio no Portal ou
no Aplicativo.</span></p>

    <p class="MsoNormal"><span lang="PT-BR">3.2. Realizar o pagamento do valor
constante da cláusula 2.2, objeto do presente contrato, observadas as condições
previstas na Cláusula 2.3.</span></p>

    <p class="MsoNormal"><span lang="PT-BR">3.3. Ser, na data da contratação, maior de
18 anos de idade e plenamente capaz.</span></p>

    <p class="MsoNormal"><span lang="PT-BR">3.4. Garantir a veracidade das informações
e dados cadastrados no Portal ou</span></p>

    <p class="MsoNormal"><span lang="PT-BR">Aplicativo, responsabilizando-se integralmente
 por  eventuais  danos decorrentes dessa omissão, comprometendo-se
a mantê-los atualizadas.</span></p>

    <p class="MsoNormal"><span lang="PT-BR">3.5. Permitir o acesso da INTERMEDIADORA
aos seus dados pessoais para os fins propostos nestes “Termos e Condições de
Uso”.</span></p>

    <p class="MsoNormal" align="left" style="margin-top:0in;margin-right:0in;
margin-bottom:2.05pt;margin-left:0in;text-align:left;text-indent:0in;
line-height:107%"><span lang="PT-BR" style="color:windowtext"> </span></p>

    <h1 style="margin-top:0in;margin-right:0in;margin-bottom:5.15pt;margin-left:
37.9pt;text-indent:-21.35pt"><span lang="PT-BR" style="line-height:108%">4.<span style="font:7.0pt &quot;Times New Roman&quot;"> </span></span><span lang="PT-BR" style="color:windowtext">SENHAS DE ACESSO AO PORTAL E AO APLICATIVO
</span></h1>

    <p class="MsoNormal"><span lang="PT-BR">4.1. A senha de acesso ao PORTAL e ao
APLICATIVO é de caráter pessoal e intransferível, sendo sua correta e cuidadosa
utilização de inteira responsabilidade do USUÁRIO. </span></p>

    <p class="MsoNormal"><span lang="PT-BR" style="font-size:11.0pt;line-height:107%;
font-family:&quot;Calibri&quot;,sans-serif"></span><span lang="PT-BR">4.2. É
terminantemente proibido o repasse da senha de acesso para terceiros. </span></p>

    <p class="MsoNormal"><span lang="PT-BR">4.3. A senha de acesso é criptografada e
deverá conter caracteres/números conforme solicitação no momento da realização
do cadastro. </span></p>

    <p class="MsoNormal"><span lang="PT-BR" style="font-size:11.0pt;line-height:107%;
font-family:&quot;Calibri&quot;,sans-serif"></span><span lang="PT-BR">4.4. A
INTERMEDIADORA não possui acesso à senha pessoal do USUÁRIO. </span></p>

    <p class="MsoNormal"><span lang="PT-BR">4.5. A senha poderá ser alterada pelo
USUÁRIO a qualquer momento, através do Portal ou pelo Aplicativo. </span></p>

    <p class="MsoNormal"><span lang="PT-BR">4.6. Em caso de perda ou extravio da senha
de acesso, o USUÁRIO deverá comunicar imediatamente a INTERMEDIADORA para que
sejam tomadas as devidas providências. Eventuais danos ocorridos em razão da
perda ou extravio da senha serão suportados integralmente pelo USUÁRIO. </span></p>

    <p class="MsoNormal"><span lang="PT-BR">4.7. É expressamente proibida a utilização
do Portal ou Aplicativo por terceiro, responsabilizando-se o USUÁRIO por
eventual cessão de sua senha particular. </span></p>

    <p class="MsoNormal" align="left" style="margin-top:0in;margin-right:0in;
margin-bottom:6.9pt;margin-left:.75in;text-align:left;text-indent:0in;
line-height:107%"><span lang="PT-BR" style="color:windowtext"> </span></p>

    <h1 style="margin-top:0in;margin-right:0in;margin-bottom:7.05pt;text-indent:-.25in"><span lang="PT-BR" style="line-height:108%">5.<span style="font:7.0pt &quot;Times New Roman&quot;"> </span></span><span lang="PT-BR" style="color:windowtext">DAS APOSTAS </span></h1>

    <p class="MsoNormal"><span lang="PT-BR" style="font-size:11.0pt;line-height:107%;
font-family:&quot;Calibri&quot;,sans-serif"></span><span lang="PT-BR">5.1. A
intermediação será realizada apenas em apostas na modalidade bolão. </span></p>

    <p class="MsoNormal"><span lang="PT-BR">5.2. Em caso de inviabilidade do
processamento das apostas em tempo hábil para o concurso escolhido pelo
USUÁRIO, em razão de caso fortuito ou força maior, como por exemplo problemas
técnicos e/ou operacionais, intempéries climáticas, culpa exclusiva de
terceiro, entre outras, a INTERMEDIADORA se exime de quaisquer
responsabilidades, cancelando automaticamente as apostas realizadas com o
estorno integral do valor pago. </span></p>

    <p class="MsoNormal"><span lang="PT-BR">5.3. Realizadas as apostas, a
INTERMEDIADORA se responsabilizará pela guarda e custódia dos respectivos
bilhetes. </span></p>

    <p class="MsoNormal"><span lang="PT-BR">5.4. A INTERMEDIADORA realiza a apuração e
faz o rateio entre os apostadores automaticamente. Em caso de premiação, todos
serão comunicados via e- </span></p>

    <p class="MsoNormal"><span lang="PT-BR">mail. O USUÁRIO também pode verificar seus
prêmios através do portal <a href="http://www.unindosonhos.com.br/"><span style="color:black">http://www.unindosonhos.com.b</span></a><a href="http://www.unindosonhos.com.br/"><span style="color:black">r</span></a><a href="http://www.unindosonhos.com.br/"><span style="color:black;text-decoration:
none">,</span></a><a href="http://www.unindosonhos.com.br/"><span style="color:black;text-decoration:none"> </span></a>na opção "Minhas
Apostas" no menu da sua conta Unindo Sonhos. </span></p>

    <p class="MsoNormal"><span lang="PT-BR">5.5. O rateio do prêmio é creditado
automaticamente na aba “Carteira do Cliente”, que se encontra no menu da sua
conta da Unindo Sonhos. </span></p>

    <p class="MsoNormal"><span lang="PT-BR">5.6. Em relação à premiação, o ganhador tem
duas alternativas: 1 - Saldo: O </span></p>

    <p class="MsoNormal"><span lang="PT-BR">USUÁRIO poderá manter o valor do prêmio em
sua carteira na sua conta da </span></p>

    <p class="MsoNormal"><span lang="PT-BR">Unindo Sonhos, para utilizá-lo em novas
apostas quando quiser; ou, 2 – Resgate: O resgate pode ser solicitado também
através da opção "Carteira do Cliente" do menu do usuário, no qual o
ganhador informará os dados de uma conta corrente ou poupança de sua
titularidade. Para sua comodidade, o valor será creditado diretamente na conta
bancária informada, no prazo de até 5 dias úteis. Não será cobrada qualquer
percentual a título de taxa sobre o valor da premiação, porém temos uma pequena
taxa de resgate, que será de R$ 5,00 (cinco reais), independentemente do valor
e do banco solicitado. </span></p>

    <p class="MsoNormal"><span lang="PT-BR">5.7. <b>As apostas só poderão ser
realizadas até às 19:59h</b> <b>(horário de Brasília/DF)</b> do dia do sorteio,
sendo que que <b>apostas feitas após esse horário não concorrerão </b>e, caso
tenha sido feito algum pagamento pelo USUÁRIO, o(s) valor(es) será(ão)
reembolsado(s), mediante requerimento, dentro de 3 (três) dias úteis após a
confirmação do pagamento pela Unindo Sonhos. </span></p>

    <p class="MsoNormal"><span lang="PT-BR">5.8. O USUÁRIO deverá, antes de concluir a
aposta, certificar-se de que todas as informações (bolão, aposta, números, etc.)
estão corretas, uma vez que o procedimento é irreversível após ter sido
concluído, não sendo possível retificação posterior e nem o estorno de
eventuais valores pagos. </span></p>

    <p class="MsoNormal"><span lang="PT-BR">5.9. <b>Informação importante ao USUÁRIO:</b>
A Unindo Sonhos apenas faz a <i>intermediação</i> das apostas, as quais são
disponibilizadas pelas Casas Lotéricas credenciadas e/ou pela CAIXA ECONÔMICA
FEDERAL e, após o sorteio ter sido realizado ou havendo a divulgação dos
números sorteados (diariamente ou em dias específicos), as Lotéricas e a Caixa
não devolvem, em hipótese alguma, os valores que pagamos pelos bolões
efetuados, de modo que a Unido Sonhos também <b>não faz a devolução dos valores
que o USUÁRIO pagou</b>. Por isso, reforça-se o disposto nos itens 5.7 e 5.8
acima, quanto aos cuidados a serem observados pelo USUÁRIO antes de finalizar
sua aposta. </span></p>

    <p class="MsoNormal" align="left" style="margin-top:0in;margin-right:0in;
margin-bottom:7.0pt;margin-left:.75in;text-align:left;text-indent:0in;
line-height:107%"><span lang="PT-BR" style="color:windowtext"> </span></p>

    <h1 style="margin-top:0in;margin-right:0in;margin-bottom:5.15pt;text-indent:-.25in"><span lang="PT-BR" style="line-height:108%">6.<span style="font:7.0pt &quot;Times New Roman&quot;"> </span></span><span lang="PT-BR" style="color:windowtext">DO ACESSO À INTERMEDIAÇÃO PELO PORTAL /
APLICATIVO </span></h1>

    <p class="MsoNormal"><span lang="PT-BR">6.1. A INTERMEDIADORA concede acesso ao
Portal e ao Aplicativo por meio de licença revogável, onerosa e intransferível
de uso. </span></p>

    <p class="MsoNormal"><span lang="PT-BR">6.2. A INTERMEDIADORA poderá, a qualquer
tempo e seu livre arbítrio, sem necessidade de aviso prévio: </span></p>

    <p class="MsoNormal"><span lang="PT-BR">6.2.1 Suspender ou excluir o acesso do
USUÁRIO do Portal e/ou Aplicativo caso este venha a adotar conduta ilícita, que
afete a moral, a ética e os bons costumes, como, por exemplo, ofender outros
usuários ou operadores, solicitar cancelamento de apostas efetuadas alegando
motivo inverídico ou fraudulento, entre outros. </span></p>

    <p class="MsoNormal"><span lang="PT-BR">6.2.2 Excluir informações constantes do
cadastro do USUÁRIO que não estejam de acordo com os presentes Termos, ou que
ofendam a moral, os bons costumes, ou ainda, que tenham denotação homofóbica,
racial, xenofóbica ou qualquer outra foram de discriminação. </span></p>

    <p class="MsoNormal"><span lang="PT-BR">6.2.3 Suspender, encerrar ou excluir o
cadastro do USUÁRIO se tiver fundadas suspeitas de que ele esteja se utilizando
das apostas para fins que transgridam a lei, em especial a lei que dispõe sobre
os crimes contra o sistema financeiro nacional (Lei nº. 7.492./1986), e do
combate à lavagem de dinheiro (Lei nº. 9.613/1998). Neste caso a INTERMEDIADORA
poderá agir de modo a informar as autoridades competentes. </span></p>

    <p class="MsoNormal"><span lang="PT-BR"> </span></p>

    <p class="MsoNormal"><span lang="PT-BR" style="font-size:11.0pt;line-height:107%;
font-family:&quot;Calibri&quot;,sans-serif"> </span><span lang="PT-BR">6.3. <b>O
USUÁRIO não poderá: </b></span></p>

    <p class="MsoNormal"><span lang="PT-BR">6.3.1 Usar o Portal ou Aplicativo para
divulgar informações ou para realizar manifestações que atentem contra qualquer
disposição legal. </span></p>

    <p class="MsoNormal"><span lang="PT-BR">6.3.2 Copiar, vender, ceder, locar,
reproduzir, doar, alienar de qualquer forma, transferir total ou parcialmente,
o Aplicativo, ainda que em partes, ou informações sobre seu código fonte. </span></p>

    <p class="MsoNormal"><span lang="PT-BR">6.3.3 Empregar artifícios, como
softwares/malware, com o intuito de burla ou causar prejuízo à INTERMEDIADORA,
sob pena de responsabilização civil e criminal. </span></p>

    <p class="MsoNormal"><span lang="PT-BR">6.3.4 Reproduzir, adaptar, modificar ou
empregar, no todo ou em parte, para qualquer fim, o Portal e/ou Aplicativo, sem
prévia autorização da INTERMEDIADORA. </span></p>

    <p class="MsoNormal"><span lang="PT-BR">6.3.5 Publicar ou fazer publicar
divulgações com intuito de denegrir a imagem da INTERMEDIADORA ou dos demais
USUÁRIOS. </span></p>

    <p class="MsoNormal"><span lang="PT-BR">6.3.6 Simular a aquisição de crédito em sua
conta junto à INTERMEDIADORA para posteriormente solicitar o estorno e assim
antecipar valores por meio de seu cartão de crédito. Restando evidenciada essa
prática, não haverá restituição em dinheiro e eventuais estornos de valores
somente poderão ser realizados por intermédio da respectiva operadora do cartão
de crédito do USUÁRIO. <br>
<br>
6.3.7 Simular a aquisição de créditos através de qualquer transação financeira,
como PIX ou boleto bancário, com o objetivo de imediatamente requerer o resgate
do montante correspondente, é considerado uma violação dos termos de serviço.
Essa conduta pode acarretar medidas legais, como bloqueio de acesso à conta no
site, anulação de transações e potencial responsabilização civil e criminal,
conformeasituação.</span></p>

    <p class="MsoNormal" align="left" style="margin-top:0in;margin-right:0in;
margin-bottom:7.0pt;margin-left:.75in;text-align:left;text-indent:0in;
line-height:107%"><span lang="PT-BR" style="color:windowtext"> </span></p>

    <h1 style="margin-top:0in;margin-right:0in;margin-bottom:7.05pt;text-indent:-.25in"><span lang="PT-BR" style="line-height:108%">7.<span style="font:7.0pt &quot;Times New Roman&quot;"> </span></span><span lang="PT-BR" style="color:windowtext">LIMITAÇÃO DE RESPONSABILIDADE E
INDENIZAÇÃO </span></h1>

    <p class="MsoNormal"><span lang="PT-BR" style="font-size:11.0pt;line-height:107%;
font-family:&quot;Calibri&quot;,sans-serif"> </span><span lang="PT-BR">7.1. A
INTERMEDIADORA não se responsabilização, em nenhuma hipótese: </span></p>

    <p class="MsoNormal"><span lang="PT-BR">7.1.1 Por eventuais prejuízos sofridos pelo
USUÁRIO em razão de falhas no sistema de informática, de telefonia ou em
servidores que o impeçam de usufruir, de qualquer forma, da intermediação
ofertada pela INTERMEDIADORA.; </span></p>

    <p class="MsoNormal"><span lang="PT-BR">7.1.2 Por eventuais prejuízos oriundos de
fraudes praticadas por terceiros, devendo o USUÁRIO manter, às suas expensas e
sob sua responsabilidade, softwares e medidas de proteção contra vírus,
malwares, Worms etc.; </span></p>

    <p class="MsoNormal"><span lang="PT-BR">7.1.3 Por situações decorrentes de caso
fortuito, força maior ou culpa exclusiva de terceiro. </span></p>

    <p class="MsoNormal"><span lang="PT-BR">7.2. A INTERMEDIADORA não garante que as
funções do Aplicativo ou Portal atendam todas as necessidades do USUÁRIO, bem
como não garante que a operação do Aplicativo ou Portal seja ininterrupta ou
livre e erros, situação com a qual fica o USUÁRIO ciente. Em caso de
indisponibilidade ou erro no </span></p>

    <p class="MsoNormal"><span lang="PT-BR">Aplicativo ou Portal, a INTERMEDIADORA
garante que, na ocorrência de eventualidades dessa estirpe, lançará mão de
todos os meios disponíveis para que as funções sejam restabelecidas o mais
rápido possível. </span></p>

    <p class="MsoNormal"><span lang="PT-BR">7.3. O USUÁRIO deverá efetuar suas apostas
em tempo hábil para que, em caso de indisponibilidade do sistema, possa
realizá-las por outro meio. </span></p>

    <p class="MsoNormal"><span lang="PT-BR">7.4. A INTERMEDIADORA não se responsabiliza
em caso de não realização de apostas por indisponibilidade ou erro do sistema,
bem como em razão da inexistência de cota. Neste caso, os valores eventualmente
pagos à INTERMEDIADORA serão estornados/restituídos ao USUÀRIO. </span></p>

    <p class="MsoNormal"><span lang="PT-BR">7.5. Na hipótese de compra simultânea de
cotas, será considerado adquirente aquele USUÁRIO que primeiro efetuar o
pagamento à INTERMEDIADORA, razão pela qual os USUÁRIOS que não tiverem a
compra finalizada receberão em sua carteira os créditos eventualmente
estornados pela INTERMEDIADORA. </span></p>

    <p class="MsoNormal"><span lang="PT-BR">7.6. A INTERMEDIADORA, não será responsável
por danos pessoais, materiais ou morais, ou qualquer prejuízo direto ou
indireto, lucros cessantes, danos emergentes, perda de uma chance que
eventualmente o USUÁRIO suporte em razão da intermediação objeto destes “Termos
e Condições de Uso”. </span></p>

    <p class="MsoNormal" align="left" style="margin-top:0in;margin-right:0in;
margin-bottom:6.75pt;margin-left:.75in;text-align:left;text-indent:0in;
line-height:107%"><span lang="PT-BR" style="color:windowtext"> </span></p>

    <h1 style="margin-top:0in;margin-right:0in;margin-bottom:5.15pt;text-indent:-.25in"><span lang="PT-BR" style="line-height:108%">8.<span style="font:7.0pt &quot;Times New Roman&quot;"> </span></span><span lang="PT-BR" style="color:windowtext">DA VIGÊNCIA </span></h1>

    <p class="MsoNormal"><span lang="PT-BR">8.1. Estes “Termos e Condições de Uso”
terão vigência por prazo indeterminado, a partir do aceite do Usuário, quando
terá força de contrato entre as partes. </span></p>

    <p class="MsoNormal"><span lang="PT-BR">8.2. Os presentes “Termos e Condições de
Uso” poderão ser modificados ou rescindidos unilateralmente pela
INTERMEDIADORA, a qualquer momento, sem qualquer ônus, mediante simples
comunicação ao USUÁRIO, por meio do próprio Aplicativo, Portal, e-mail, ou
outro meio idôneo. </span></p>

    <p class="MsoNormal" align="left" style="margin-top:0in;margin-right:0in;
margin-bottom:7.0pt;margin-left:.75in;text-align:left;text-indent:0in;
line-height:107%"><span lang="PT-BR" style="color:windowtext"> </span></p>

    <h1 style="margin-top:0in;margin-right:0in;margin-bottom:5.15pt;text-indent:-.25in"><span lang="PT-BR" style="line-height:108%">9.<span style="font:7.0pt &quot;Times New Roman&quot;"> </span></span><span lang="PT-BR" style="color:windowtext">DAS REGRAS GERAIS, DA LEGISLAÇÃO APLICÁVEL
E DO FORO </span></h1>

    <p class="MsoNormal"><span lang="PT-BR">9.1. Na utilização do Aplicativo e do
Portal, O USUÁRIO se compromete a agir com responsabilidade, lisura e a
respeitar a lei, a moral e os bons costumes. </span></p>

    <p class="MsoNormal"><span lang="PT-BR">9.2. As disposições destes “Termos e
Condições de Uso”, bem como quaisquer informações ou orientações divulgadas por
meio do Portal ou do Aplicativo constituem-se em normas que regem a relação
entre USUÁRIO E INTERMEDIADORA. </span></p>

    <p class="MsoNormal"><span lang="PT-BR">9.3. A relação contratual entre USUÁRIO e
INTERMEDIADORA é regida pelas disposições do Código Civil relativas ao Contrato
de Mandato, artigos 653 e seguintes da Lei nº. 10.406/2002. </span></p>

    <p class="MsoNormal"><span lang="PT-BR">9.4. Em razão das regras nacionais e
internacionais relativas à prevenção de crimes de corrupção, lavagem de
dinheiro e ao financiamento de atividades criminosas, a INTERMEDIADORA poderá
compartilhar quaisquer informações que detenha em razão desta relação
contratual, com quaisquer órgãos governamentais. </span></p>

    <p class="MsoNormal"><span lang="PT-BR">9.5. Em nenhuma hipótese o USUÁRIO terá
acesso ao código fonte do Portal ou Aplicativo, por se tratar de propriedade
intelectual da INTERMEDIADORA. </span></p>

    <p class="MsoNormal"><span lang="PT-BR">9.6. Todo conteúdo constante do Portal ou
do Aplicativo, incluindo, mas não se limitando, os textos, gráficos, imagens,
logos, ícones, fotografias, material editorial, notificações, são de
propriedade exclusiva da INTERMEDIADORA, sendo protegidos pela lei de
Propriedade Intelectual (Lei n. 9.609/98) </span></p>

    <p class="MsoNormal"><span lang="PT-BR">9.7. O USUÁRIO é responsável pelas
informações e dados bancários informados à INTERMEDIADORA, não se podendo a ela
atribuir eventuais prejuízos pelo não recebimento de prêmios em razão de
incorreções nos dados bancários informados. </span></p>

    <p class="MsoNormal"><span lang="PT-BR">9.8. <b>Para fins de informação, a
INTERMEDIADORA esclarece e salienta que não possui qualquer vínculo com a CAIXA
ECONÔMICA FEDERAL nem com Lotéricas Credenciadas, atuando apenas na
intermediação entre o USUÁRIO e aquelas na efetivação das apostas. </b></span></p>

    <p class="MsoNormal"><span lang="PT-BR">9.9. Em caso de contemplação, o prêmio, no
importe mínimo de R$ 20,00 (vinte reais), será transferido ao USUÁRIO, em até 5
(cinco) dia úteis, contados a partir do sorteio, em parcela única, por meio de
transferência bancária para conta e banco por ele indicada, na proporção da(s)
Cota(s) por ele adquirida(s), sendo o comprovante de transferência prova
irrefutável da quitação, não tendo o USUÁRIO mais nada a receber ou reclamar.
Para premiações que ultrapassem o valor R$ 10.000,00 (dez mil reais), o prazo
máximo para o pagamento são de até 10 (dez) dias úteis a partir da
confirmaçãodapremiação. Esse prazo se faz necessário, pois o
bilhete permanecerá sob custódia do banco e somente após a liberação,
prosseguiremos com os pagamentos das respectivaspremiações. </span></p>

    <p class="MsoNormal"><span lang="PT-BR">9.10. O valor do prêmio pode variar para
mais ou para menos a depender exclusivamente das regras do respectivo concurso
conforme normas estipuladas pela CAIXA ECONÔMICA FEDERAL, a qual compete também
a divulgação dos valores em cada caso, bem como não respondendo a
INTERMEDIADORA, em nenhuma hipótese, por essa variação de valor. </span></p>

    <p class="MsoNormal"><span lang="PT-BR">9.11. A INTERMEDIADORA não é uma
instituição financeira, em razão disso não serão pagos quaisquer acréscimos ao
valor que eventualmente o USUÁRIO alegar fazer <i>jus</i>. </span></p>

    <p class="MsoNormal"><span lang="PT-BR">9.12. Caso necessário, poderão ser
solicitados documentos comprobatórios para atestar a legitimidade da transação
de transferência dos créditos/prêmios para a conta do USUÁRIO. </span></p>

    <p class="MsoNormal"><span lang="PT-BR">9.13. Caberá ao USUÁRIO recolher os
tributos relativos a eventual premiação. Neste caso, ao repassar o prêmio
correspondente à cota do USUÁRIO, este AUTORIZA a retenção de valores dessa
natureza. </span></p>

    <p class="MsoNormal"><span lang="PT-BR">9.14. A CONTRATADA se reserva o direito de
suspender, encerrar ou excluir a participação do USUÁRIO em apostas, se tiver
fundadas suspeitas de que o USUÁRIO esteja se utilizando das apostas para fins
que transgridam a lei, em especial a lei que dispõe sobre os crimes contra o
sistema financeiro nacional (Lei nº. 7.492/1986), e do combate à lavagem de
dinheiro (Lei nº. 9.613/1998). Neste caso a CONTRATADA poderá agir de modo a
informar as autoridades competentes. </span></p>

    <p class="MsoNormal"><span lang="PT-BR">9.15. O USUÁRIO declara expressamente que
leu integralmente todas as cláusulas destes “Termos e Condições de Uso”, bem
como todas as informações constantes no Portal Unindo Sonhos, com as quais
concorda sem quaisquer reservas. </span></p>

    <p class="MsoNormal"><span lang="PT-BR">9.16. As Partes elegem o Foro da Comarca de
Boa Vista/RR para dirimir judicialmente eventuais controvérsias que surjam em
decorrência do uso do Portal ou Aplicativo Unindo Sonhos. </span></p>

    <p class="MsoNormal" align="left" style="margin-top:0in;margin-right:0in;
margin-bottom:2.1pt;margin-left:0in;text-align:left;text-indent:0in;line-height:
107%"><span lang="PT-BR" style="color:windowtext"> </span></p>

    <p class="MsoNormal" style="margin-top:0in;margin-right:0in;margin-bottom:1.0pt;
margin-left:.5pt"><span lang="PT-BR" style="color:windowtext">Boa Vista, 07 de setembro
de 2024. </span></p>

    <p class="MsoNormal" align="left" style="margin-top:0in;margin-right:0in;
margin-bottom:1.05pt;margin-left:0in;text-align:left;text-indent:0in;
line-height:107%"><span lang="PT-BR" style="color:windowtext"> </span></p>

    <p class="MsoNormal" align="left" style="margin-top:0in;margin-right:0in;
margin-bottom:1.15pt;margin-left:0in;text-align:left;text-indent:0in;
line-height:107%"><span lang="PT-BR" style="color:windowtext"> </span></p>

    <p class="MsoNormal" align="left" style="margin-top:0in;margin-right:0in;
margin-bottom:1.05pt;margin-left:0in;text-align:left;text-indent:0in;
line-height:107%"><span lang="PT-BR" style="color:windowtext"> </span></p>

    <p class="MsoNormal" align="left" style="margin:0in;text-align:left;text-indent:
0in;line-height:107%"><span lang="PT-BR" style="color:windowtext"> </span></p>

    <p class="MsoNormal" align="left" style="margin-top:0in;margin-right:0in;
margin-bottom:2.6pt;margin-left:0in;text-align:left;text-indent:0in;line-height:
107%"><span lang="PT-BR" style="color:windowtext">  </span></p>
</div>
  `;
}
