/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-return-assign */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React, { Fragment } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { isMobile } from 'react-device-detect';
import * as appActions from '../../store/app/actions';
import * as cartActions from '../../store/cart/actions';

import logo from '../../assets/images/icon-loteria.svg';
import iconThrophy from '../../assets/images/icon-throphy.svg';
import iconTicket from '../../assets/images/icon-ticket.svg';
import iconView from '../../assets/images/icon-view.svg';

import Button from '../Button';
import JackpotModal from '../JackpotModal';
import currencyFormat from '../../helpers/currencyFormat';
import JackpotTimeLeft from '../JackpotTimeLeft';
// import convertCurrencyWords from '../../helpers/convertCurrencyWords';

import './style.scss';
import cartService from '../../services/cart';
import PhoneIcon from '../../assets/images/icons/phone.svg';

class JackpotBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      quantity: 1,

      loading: false,
      open: false,

      buttonLabel: ['', ''],
    };

    this.handleCalc = this.handleCalc.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.addToCart = this.addToCart.bind(this);
    this.handleModal = this.handleModal.bind(this);

    this._addCartFeedback = React.createRef;
  }

  handleCalc(type) {
    const { jackpot } = this.props;

    let result = type === 'sum' ? this.state.quantity + 1 : this.state.quantity - 1;

    if (result <= 1) result = 1;
    if (result >= 3) result = 3;

    this.setState({ ...this.state, quantity: result });
  }

  handleChange(event, max = 3) {
    if (event.target.value < 1) { return; }

    this.setState({ ...this.state, quantity: event.target.value > max ? max : event.target.value });
  }

  handleModal() {
    this.setState({ ...this.state, open: !this.state.open });
  }

  async addToCart(jackpot) {
    this.setState({ ...this.state, loading: true });

    let _feedback = { ...this.state, loading: false, buttonLabel: ['', ''] };

    await cartService.addItemToCart(jackpot.id, this.state.quantity, localStorage.getItem('cartKey')).then((response) => {
      if (response.code) {
        if (response.code === 911) {
          _feedback = {
            ...this.state,
            loading: false,
            buttonLabel: ['error', 'Máximo de 3 cotas por bolão'],
          };

          return;
        }

        if (response.code !== 429) {
          this.props.cartActions.destroyCart();
          this.addToCart(jackpot);
        }

        return;
      }

      if (!response?.data?.cartKey) {
        _feedback = {
          ...this.state,
          loading: false,
          buttonLabel: ['error', response.code === 429 ? 'Muitas tentativas, tente mais tarde.' : 'Cotas indisponíveis'],
        };

        return;
      }

	    this.props.cartActions.addToCart(response);

	    _feedback = {
        ...this.state,
        loading: false,
        buttonLabel: ['success', 'Adicionado ao carrinho!'],
      };
    });

    this.setState(_feedback);
    this._addCartFeedback && this._addCartFeedback.classList.add('show');

    setTimeout(() => {
      this.setState({ ...this.state, buttonLabel: '' });
      this._addCartFeedback && this._addCartFeedback.classList.remove('show');
    }, 4500);
  }

  render() {
    const { jackpot } = this.props;
    const { open } = this.state;

    return (
      <>
        <section className="jackpot-box">
          <div className={`header ${jackpot.type_game.slug}`}>
            <span className="title">
              <img src={logo} alt="logotipo das loterias federais" width="20" />
              <span>{jackpot.name}</span>
              <span className="title-price">
                {moment(jackpot.draw_date, 'DD/MM/YYYY H:m:s').format('DD/MM/YYYY')}
              </span>
            </span>
          </div>

          <div className="modal-body">
            <ul>
              <li className="ref">
                Bolão:
                {jackpot.ref}
              </li>
              <li className="ref">
                Concurso:
                {jackpot.sku}
              </li>
              <li className="space" />
              {/* { !convertCurrencyWords(jackpot.prize_amount) ? null : <li><img src={ iconThrophy } alt="Ícone de troféu"    /> <strong dangerouslySetInnerHTML={{ __html: convertCurrencyWords(jackpot.prize_amount) }}></strong></li> } */}
              {jackpot.prize_amount > 0 ? (
                <li>
                  <img src={iconThrophy} alt="Ícone de troféu" />
                  {' '}
                  <strong>{currencyFormat(jackpot.prize_amount)}</strong>
                </li>
              ) : (
                <li>
                  <img src={iconThrophy} alt="Ícone de troféu" />
                  {' '}
                  A definir
                </li>
              )}
              <li>
                <JackpotTimeLeft
                  timeTillDate={jackpot.end_date}
                  timeFormat="DD/MM/YYYY HH:mm:ss"
                  clock
                />
              </li>
              {/* <li><img src={iconProbability} alt="Ícone de probabilidade" /> Probabilidade alta</li> */}
              <li>
                <img src={iconTicket} alt="Ícone de um ticket" />
                {' '}
                {jackpot.shares_available_quantity}
                {' '}
                /
                {' '}
                {jackpot.shares_quantity}
                {' '}
                cotas
              </li>
              <li className="show" onClick={this.handleModal}>
                <img src={iconView} alt="Ícone com um olho" />
                {' '}
                Ver o jogo
              </li>
            </ul>
            <hr />
            <span className="value">
              {currencyFormat(jackpot.shares_regular_price * this.state.quantity)}
            </span>
            <div className="quantity">
              <span className="sub" onClick={() => this.handleCalc('sub')}>
                -
              </span>
              <input
                type="number"
                pattern="[0-9]"
                min="1"
                max={jackpot.shares_available_quantity}
                value={this.state.quantity}
                // onChange={(e) => this.handleChange(e, jackpot.shares_available_quantity)}
                onChange={(e) => this.handleChange(e)}
              />
              <span className="sum" onClick={() => this.handleCalc('sum')}>
                +
              </span>
            </div>
            <div className="action">
              <Button
                type="button"
                disabled={this.state.loading}
                loading={this.state.loading}
                width="100%"
                color="green"
                label="Comprar cotas"
                action={() => this.addToCart(jackpot)}
              />
              <small style={{ color: this.state.buttonLabel[0] === 'success' ? '#0091CE' : '#F16165' }} ref={(ref) => (this._addCartFeedback = ref)}>
                {this.state.buttonLabel[1]}
              </small>
            </div>
            {!!jackpot?.mus_reward_info?.mus && (
            <a target="_blank" href={process.env.REACT_APP_PLAY_URL || 'https://play.unindosonhos.com.br'} rel="noreferrer" className="jackpot-mus-card">
              <img src={PhoneIcon} width={12} height={18} alt="Moeda MUS" />
              <span>Benefícios exclusivos no app</span>
            </a>
	          )}
          </div>
        </section>
        {!isMobile ? (
          open ? (
            <JackpotModal
              jackpotId={jackpot.id}
              open={open}
              handleModal={this.handleModal}
            />
          ) : null
        ) : open ? (
          <JackpotModal
            jackpotId={jackpot.id}
            open={open}
            handleModal={this.handleModal}
          />
        ) : null}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.accountReducer.userData,
  appData: state.appReducer,
  cartData: state.cartReducer,
});
const mapDispatchToProps = (dispatch) => ({
  appActions: bindActionCreators(appActions, dispatch),
  cartActions: bindActionCreators(cartActions, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(JackpotBox);
