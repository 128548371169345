import React from 'react';

import json from '../../data/privacy-terms';
import '../TermsAndConditions/style.scss';

class TermsAndCondition extends React.Component {
  render() {
    return (
      <main id="terms-and-conditions">
        <div className="container">
          <div className="row">
            <div style={{ marginTop: 80, color: 'darkgray' }} dangerouslySetInnerHTML={{ __html: json.data }} />
          </div>
        </div>
      </main>
    );
  }
}

export default TermsAndCondition;
