import React from 'react';
import { UseTermsHTML } from '../../data/use-terms';
import './style.scss';

class TermsAndCondition extends React.Component {
  render() {
    return (
      <main id="terms-and-conditions">
        <div className="container">
          <div className="row">
            <div style={{ marginTop: 80, color: 'darkgray' }} dangerouslySetInnerHTML={{ __html: UseTermsHTML() }} />
          </div>
        </div>
      </main>
    );
  }
}
export default TermsAndCondition;
